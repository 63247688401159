<template>
  <div class="warpFun" :style="{ minHeight: minHeight + 'px' }">
    <div>
      <img src="~@/assets/new-icon/bg.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      minHeight: 1280,
    };
  },
  created(){
    console.log(this.minHeight = document.documentElement.clientHeight);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.warpFun {
  display: flex;
  background: #fff;
  margin-left: -1.8rem;
  min-width: 1280px;

  justify-content: center;
  align-items: center;
}
</style>
